/**
 * Splits an array into evenly sized chunks
 * ['a', 'b', 'c', 'd', 'e']
 * chunkSize = 2:
 * [['a', 'b'], ['c', 'd'], ['e]]
 */
export const splitInChunks = <T>(
  list: Array<T>,
  chunkSize: number = 10,
): T[][] => {
  return list
    .map((_item, index) => {
      return index % chunkSize ? [] : [list.slice(index, index + chunkSize)];
    })
    .flat();
};
