import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { env, request, splitInChunks } from '../helper';
import { ProductInterface } from '@interfaces';

@customElement('page-new-products')
export class PageNewProducts extends LitElement {
  static styles = css`
    :before,
    :after,
    * {
      box-sizing: border-box;
    }

    fluent-progress {
      --accent-fill-rest: black;
      --stroke-width: 3;
    }

    fluent-progress::part(determinate) {
      transition: unset;
    }

    #page-product-wrapper {
      display: grid;

      grid-template-areas:
        'page-info'
        'header'
        'products'
        'footer';

      grid-template-rows: 128px 320px 1028px;
      align-items: start;

      height: 100vh;
      background: linear-gradient(rgb(49 153 168), rgb(145, 152, 229));
    }

    #page-info {
      grid-area: page-info;

      display: grid;
      grid-template-columns: 1fr auto;
      grid-area: page-info;
      gap: calc(var(--base-gap) * 2);
      align-items: center;
      justify-content: space-between;

      padding: calc(var(--base-gap) * 2);
      font-size: 3.5rem;

      color: #000;
      background-color: #fff;
    }

    #header {
      grid-area: header;
    }

    #product-grid {
      display: grid;
      gap: calc(var(--base-gap) * 2);
      grid-area: products;
      grid-auto-rows: 178px;

      padding: calc(var(--base-gap) * 2);
    }

    #footer {
      grid-area: footer;
    }

    img {
      max-width: 100%;
    }
  `;

  @state()
  private progressValue = 0;

  @state()
  private currentIndex = 0;

  @state()
  private numberOfChunks = 0;

  @state()
  private productContent!: Promise<ProductInterface[]>;

  @state()
  private products: ProductInterface[] = [];
  // Flag to show a loading spinner until products have been loaded
  @state()
  private productsLoading = true;

  @state()
  private lastIndex = 0;

  private apiUrl = env.apiUrl;
  private apiPort = env.apiPort;

  private productChunks: ProductInterface[][] = [[]];

  private timer!: ReturnType<typeof setInterval>;
  private interval = 15000;

  connectedCallback() {
    super.connectedCallback();

    this.productContent = request<ProductInterface[]>(
      `${this.apiUrl}${this.apiPort}/products/all`,
    );

    this.productContent.then((products) => {
      // Promise has been resolved -> products are loaded
      this.productsLoading = false;
      // Destructoring is needed to trigger re-render
      this.products = [...products];
      // Products are chopped into evenly sized chunks
      this.productChunks = [
        ...splitInChunks<ProductInterface>(this.products, 5),
      ];

      // Used to display how many pages of products are displayed
      this.numberOfChunks = this.productChunks.length;

      this.lastIndex = this.numberOfChunks - 1;

      console.log(`number of pages: ${this.numberOfChunks}`);
    });

    if (this.productChunks.length > 0) {
      this.timer = setInterval(() => {
        this.progressValue += 11.1111;
      }, this.interval / 10);

      setInterval(() => {
        // Remaining time
        this.timer = setInterval(() => {
          this.progressValue += 11.1111;
        }, this.interval / 10);

        this.progressValue = 0;
        this.timer && clearInterval(this.timer);
        // Increase currentIndex to scroll to the next page / chunk of products
        this.currentIndex =
          this.currentIndex < this.lastIndex
            ? // Increase until last image was displayed
              (this.currentIndex += 1)
            : // Reset to first image
              (this.currentIndex = 0);

        console.log(this.currentIndex);
      }, this.interval);
    }
  }

  render() {
    return html`
      <div id="page-product-wrapper">
        <span id="page-info">
          <fluent-progress value=${this.progressValue}></fluent-progress>
          <span>${this.currentIndex + 1} / ${this.numberOfChunks}</span>
        </span>
        <img
          src="/assets/bg/header.png"
          id="header"
          alt="Header"
        />

        <div id="product-grid">
          ${this.productChunks[this.currentIndex]
            .sort(
              (productA, productB) =>
                productA.articleNumber - productB.articleNumber,
            )
            .map((product) => {
              return html`<app-product
                name=${product.name}
                price=${product.price}
                unit=${product.unit}
                articleNumber=${product.articleNumber}
              ></app-product>`;
            })}
        </div>

        <img
          src="/assets/bg/footer.png"
          id="footer"
          alt="Footer"
        />
      </div>

      ${this.productsLoading
        ? // Loading
          html`<div id="loading-container">
            <fluent-progress-ring></fluent-progress-ring>
            <span>Produkte laden</span>
          </div>`
        : // Loaded
          html``}
    `;
  }
}

