import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('page-links')
export class PageLinks extends LitElement {
  static styles = css`
    #link-container {
      display: grid;
      gap: var(--base-gap);
      padding: var(--base-gap);
      margin-block-end: calc(var(--base-gap) * 5);
      max-width: 640px;
    }

    .two-tiles {
      display: grid;
      gap: var(--base-gap);
    }

    .two-tiles h2 {
      grid-column: 1/3;
      margin-bottom: 0;
    }

    fluent-card {
      padding: var(--base-gap);
      text-align: center;
    }
  `;

  render() {
    return html`
      <app-header></app-header>

      <div id="link-container">
        <!-- Products -->
        <div class="two-tiles">
          <h2>Produkte</h2>
          <fluent-anchor
            href="products/all"
            appearance="hypertext"
            title="Produkte anzeigen"
          >
            <fluent-card>Anzeigen</fluent-card>
          </fluent-anchor>
          <fluent-anchor
            href="products/edit"
            appearance="hypertext"
            title="Produkte anzeigen und als CSV hochladen"
          >
            <fluent-card>Bearbeiten</fluent-card>
          </fluent-anchor>
        </div>
        <!-- Promotions -->
        <div class="two-tiles">
          <h2>Promotions</h2>
          <fluent-anchor
            href="promotions/active"
            appearance="hypertext"
            title="Derzeit aktive Promotions anzeigen"
          >
            <fluent-card>Anzeigen</fluent-card>
          </fluent-anchor>
          <fluent-anchor
            href="promotions/edit"
            appearance="hypertext"
            title="Promotions anzeigen & anlegen"
          >
            <fluent-card>Bearbeiten</fluent-card>
          </fluent-anchor>
        </div>

        <h2>Verschiedenes</h2>
        <fluent-anchor
          href="settings"
          appearance="hypertext"
          title="Einstellungen"
        >
          <fluent-card>Einstellungen</fluent-card>
        </fluent-anchor>

        <fluent-anchor href="help" appearance="hypertext" title="Hilfe & FAQ">
          <fluent-card>Hilfe & FAQ</fluent-card>
        </fluent-anchor>

        <fluent-anchor
          href="changes"
          appearance="hypertext"
          title="Änderungsprotokoll (Changelog)"
        >
          <fluent-card>Änderungsprotokoll (Changelog)</fluent-card>
        </fluent-anchor>

        <fluent-anchor href="debug" appearance="hypertext" title="Debugging">
          <fluent-card>Debugging</fluent-card>
        </fluent-anchor>
      </div>
    `;
  }
}
