import { customElement, state } from 'lit/decorators.js';
import { distinctUntilChanged } from 'rxjs/operators';
import { env, request, patchSetting } from '../helper';
import { LitElement, html, css } from 'lit';
import { map } from 'lit/directives/map.js';
import { range } from 'lit/directives/range.js';
import { SettingsInterface } from '@interfaces';
import { Subject, debounceTime } from 'rxjs';

@customElement('page-settings')
export class PageSettings extends LitElement {
  static styles = css`
    #settings-wrapper {
      padding: var(--base-gap);
    }

    p {
      max-width: 620px;
    }

    #settings-slider {
      display: flex;
      flex-direction: column;
      gap: var(--base-gap);
    }

    .intervall-wrapper {
      display: flex;
      align-items: center;
      gap: var(--base-gap);
    }

    label {
      font-size: 1.3rem;
    }
  `;

  private apiUrl = env.apiUrl;
  private apiPort = env.apiPort;
  /**
   * RxJS related variables
   */
  private bufferedProductIntervall$ = new Subject<number>();
  private bufferedPromotionIntervall$ = new Subject<number>();
  private debounceDueTime = 1000;

  private productIntervallResponse!: Promise<SettingsInterface>;
  private promotionIntervallResponse!: Promise<SettingsInterface>;

  private productIntervalRoute = `${this.apiUrl}${this.apiPort}/settings/productInterval`;
  private promotionIntervalRoute = `${this.apiUrl}${this.apiPort}/settings/promotionInterval`;

  @state()
  productIntervall = 20;

  @state()
  promotionIntervall = 30;

  connectedCallback(): void {
    super.connectedCallback();

    this.productIntervallResponse = request<SettingsInterface>(
      this.productIntervalRoute,
    );

    this.productIntervallResponse.then((response) => {
      this.productIntervall = response.productInterval || this.productIntervall;
      console.log(this.productIntervall);
    });

    this.bufferedProductIntervall$
      .pipe(debounceTime(this.debounceDueTime), distinctUntilChanged())
      .subscribe(async (value) => {
        console.log(`Productintervall senden: ${value}`);
        await patchSetting(this.productIntervalRoute, 'productInterval', value);
      });

    this.promotionIntervallResponse = request<SettingsInterface>(
      this.promotionIntervalRoute,
    );

    this.promotionIntervallResponse.then((response) => {
      this.promotionIntervall =
        response.promotionInterval || this.promotionIntervall;
      console.log(this.promotionIntervall);
    });

    this.bufferedPromotionIntervall$
      .pipe(debounceTime(this.debounceDueTime), distinctUntilChanged())
      .subscribe(async (value) => {
        console.log(`Promotionintervall senden: ${value}`);
        await patchSetting(
          this.promotionIntervalRoute,
          'promotionInterval',
          value,
        );
      });
  }

  render() {
    return html`
      <app-header ?enableBack="${true}"></app-header>

      <div id="settings-wrapper">
        <h2>Einstellungen</h2>
        <p>
          Vorgenommene Änderungen werden automatisch übernommen. Es bedarf
          keiner erneuten Bestätigung. Bitte beachten Sie: Änderungen werden auf
          den Anzeigen jeweils nur zur vollen Stunde neu geladen - in ihrem
          Browser sind diese bereits nach einem Seitenaufruf aktiv.
        </p>

        <div id="settings-slider">
          <h3>Produkt-Intervall (Sekunden)</h3>
          <div class="intervall-wrapper">
            <fluent-slider
              .value=${this.productIntervall}
              @change=${(e: Event) => {
                const { value } = e.currentTarget as HTMLInputElement;
                this.productIntervall = parseInt(value);

                this.bufferedProductIntervall$.next(parseInt(value));
              }}
              max="60"
              min="1"
              step="1"
              style="max-width: 300px;"
              title="Wie schnell soll eine Seite an Produkten wechseln?"
              id="product-intervall-slider"
            >
              ${map(range(0, 65, 5), (i) => {
                const tickLabel = i === 0 ? i + 1 : i;
                return html`<fluent-slider-label position=${tickLabel}
                  >${tickLabel}</fluent-slider-label
                >`;
              })}
            </fluent-slider>
            <label for="product-intervall-slider"
              >${this.productIntervall}</label
            >
          </div>

          <h3>Promotion-Intervall (Sekunden)</h3>
          <div class="intervall-wrapper">
            <fluent-slider
              .value=${this.promotionIntervall}
              @change=${(e: Event) => {
                const { value } = e.currentTarget as HTMLInputElement;
                this.promotionIntervall = parseInt(value);

                this.bufferedPromotionIntervall$.next(parseInt(value));
              }}
              max="60"
              min="1"
              step="1"
              style="max-width: 300px;"
              title="Für welche Dauer soll eine Promotion angezeigt werden?"
              id="promotion-intervall-slider"
            >
              ${map(range(0, 65, 5), (i) => {
                const tickLabel = i === 0 ? i + 1 : i;
                return html`<fluent-slider-label position=${tickLabel}
                  >${tickLabel}</fluent-slider-label
                >`;
              })}
            </fluent-slider>
            <label for="promotion-intervall-slider"
              >${this.promotionIntervall}</label
            >
          </div>
        </div>
      </div>
    `;
  }
}

