import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Router } from '@vaadin/router';
import { registerSW } from 'virtual:pwa-register';

import {
  fluentAnchor,
  fluentButton,
  fluentCard,
  fluentDialog,
  fluentProgress,
  fluentProgressRing,
  fluentRadio,
  fluentRadioGroup,
  fluentSlider,
  fluentSliderLabel,
  fluentTextField,
  provideFluentDesignSystem,
} from '@fluentui/web-components';

provideFluentDesignSystem().register(
  fluentAnchor(),
  fluentButton(),
  fluentCard(),
  fluentDialog(),
  fluentProgress(),
  fluentProgressRing(),
  fluentRadio(),
  fluentRadioGroup(),
  fluentSlider(),
  fluentSliderLabel(),
  fluentTextField(),
);

import './script/components/copyright';
import './script/components/header';
import './script/components/product';
import './script/components/promotion-dialog';
import './script/components/promotion-gallery';
import './script/components/version';
import './script/pages/page-changes';
import './script/pages/page-debug';
import './script/pages/page-home';
import './script/pages/page-links';
import './script/pages/page-new-products';
import './script/pages/page-products-edit';
import './script/pages/page-settings';

import './styles/global.css';

// this function lives within our app-index component
const mapPathToTitle = (pathname: string): string => {
  const pathToTitleMap: any = {
    '/': 'Home',
    '/about': 'About',
    '/changes': 'Änderungsprotokoll (Changelog)',
    '/debug': 'Debug',
    '/help': 'Hilfe & FAQ',
    '/products/all': 'Produkte',
    '/products/edit': 'Produkte bearbeiten',
    '/promotions/active': 'Promotions (aktiv)',
    '/promotions/edit': 'Promotions bearbeiten',
    '/settings': 'Einstellungen',
  };
  return pathToTitleMap[pathname];
};

@customElement('app-index')
export class AppIndex extends LitElement {
  shadowRoot: any;
  public copyRightTag: string = `© ${new Date().getFullYear()}, EAH Jena - Lern und Future Store`;

  static get styles() {
    return css`
      main {
        padding: 0;
      }

      #routerOutlet > * {
        width: 100% !important;
      }

      #routerOutlet > .leaving {
        animation: 160ms fadeOut ease-in-out;
      }

      #routerOutlet > .entering {
        animation: 160ms fadeIn linear;
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
        }

        to {
          opacity: 0;
        }
      }

      @keyframes fadeIn {
        from {
          opacity: 0.2;
        }

        to {
          opacity: 1;
        }
      }
    `;
  }

  constructor() {
    super();
  }

  firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/

    // For more info on using the @vaadin/router check here https://vaadin.com/router
    const router = new Router(this.shadowRoot?.querySelector('#routerOutlet'));
    router.setRoutes([
      // temporarily cast to any because of a Type bug with the router
      {
        path: '',
        animate: true,
        children: [
          { path: '/', component: 'page-links' },
          {
            path: '/about',
            component: 'page-about',
            action: async () => {
              await import('./script/pages/page-about.js');
            },
          },
          {
            path: '/products/all-old',
            component: 'page-products',
            action: async () => {
              await import('./script/pages/page-products.js');
            },
          },
          {
            path: '/products/edit',
            component: 'page-products-edit',
            action: async () => {
              await import('./script/pages/page-products-edit.js');
            },
          },
          {
            path: '/promotions/active',
            component: 'page-promotions-active',
            action: async () => {
              await import('./script/pages/page-promotions-active.js');
            },
          },
          {
            path: '/promotions/edit',
            component: 'page-promotions-edit',
            action: async () => {
              await import('./script/pages/page-promotions-edit.js');
            },
          },
          {
            path: '/settings',
            component: 'page-settings',
            action: async () => {
              await import('./script/pages/page-settings.js');
            },
          },
          {
            path: '/debug',
            component: 'page-debug',
            action: async () => {
              await import('./script/pages/page-debug.js');
            },
          },
          {
            path: '/help',
            component: 'page-help',
            action: async () => {
              await import('./script/pages/page-help.js');
            },
          },
          {
            path: '/changes',
            component: 'page-changes',
            action: async () => {
              await import('./script/pages/page-changes.js');
            },
          },
          {
            path: '/products/all',
            component: 'page-new-products',
            action: async () => {
              await import('./script/pages/page-new-products.js');
            },
          },
        ],
      } as any,
    ]);
    registerSW({ immediate: true });

    window.addEventListener(`vaadin-router-location-changed`, (event) => {
      document.title = mapPathToTitle(event.detail.location.pathname);

      document.body.style.backgroundColor =
        event.detail.location.pathname === '/products/all' ? '#181818' : '#fff';
    });
  }

  render() {
    return html`
      <div>
        <main>
          <div id="routerOutlet"></div>
        </main>
      </div>
      <app-copyright></app-copyright>
      <app-version></app-version>
    `;
  }
}

