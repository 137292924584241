import {
  ProductInterface,
  PromotionInterface,
  SettingsInterface,
} from '../interfaces';

/**
 * Generic request method use as follows:
 * request<PromotionInterface[]>(
 *   'http://localhost:1993/promotions/edit',
 * );
 */
export const request = async <T>(url: string, method = 'GET'): Promise<T> => {
  const response = await fetch(url, {
    method,
  });
  const responseJson = await response.json();
  // TODO: delete / ignore in production
  await new Promise<void>((r) => setTimeout(() => r(), 1000));

  return responseJson;
};
/**
 * Deletes resources
 */
export const deleteRequest = async <T>(
  url: string,
  method = 'DELETE',
): Promise<T> => {
  const response = await fetch(url, {
    method,
    headers: { 'Content-Type': 'application/json' },
  });

  return await response.json();
};

export const addProduct = async (
  url: string,
  product: ProductInterface,
): Promise<ProductInterface> => {
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(product),
  });

  const responseJson = await response.json();

  return responseJson;
};

export const addPromotion = async (
  url: string,
  promotion: PromotionInterface,
): Promise<PromotionInterface> => {
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(promotion),
  });

  const responseJson = await response.json();

  return responseJson;
};

export const patchPromotion = async (
  url: string,
  promotion: PromotionInterface,
): Promise<PromotionInterface> => {
  const response = await fetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(promotion),
  });

  const responseJson = await response.json();

  return responseJson;
};

export const patchSetting = async (
  url: string,
  settingsKey: 'productInterval' | 'promotionInterval',
  settingsValue: number,
): Promise<SettingsInterface> => {
  const response = await fetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      settingsKey: settingsKey,
      settingsValue: settingsValue,
    }),
  });

  const responseJson = await response.json();

  return responseJson;
};
