import { createRef, ref } from 'lit/directives/ref.js';
import { css, html, LitElement } from 'lit';
import { CsvProductInterface, ProductInterface } from '@interfaces';
import { customElement, state } from 'lit/decorators.js';
import { addProduct, deleteRequest, env, request } from '../helper';
import { parse } from 'papaparse';

@customElement('page-products-edit')
export class PageEditProducts extends LitElement {
  static styles = css`
    #content-wrapper {
      padding: var(--base-gap);
    }

    #loading-request {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    #loading-request fluent-progress-ring::part(indeterminate-indicator-1) {
      stroke: #000;
    }

    #loading-container {
      display: flex;
      align-items: center;
      gap: var(--base-gap);
    }

    #dialog-content {
      display: grid;
      gap: var(--base-gap);
      grid-template-areas:
        'heading'
        'selection'
        'csv-content'
        'dialog-actions';
      grid-template-rows: auto auto 1fr auto;
      align-items: start;

      padding: var(--base-gap);
      height: 100%;
      box-sizing: border-box;
    }

    #dialog-csv-content {
      grid-area: csv-content;
      height: 100%;
      overflow: auto;
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }

    th {
      position: sticky;
      top: 0;
      background-color: rgb(251, 251, 251);
    }

    th,
    td {
      padding: calc(var(--base-gap) / 2);
    }

    td.price {
      text-align: end;
    }

    td.unit {
      text-align: center;
    }

    #dialog-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: calc(var(--base-gap) / 2);
      grid-area: dialog-actions;
    }
  `;
  /**
   * To show a spinner until the API request has been resolved
   */
  @state()
  private productsLoading = true;
  /**
   * Promise for products saved in SQLite
   */
  @state()
  private productContent!: Promise<ProductInterface[]>;
  /**
   * Products to be displayed in a table
   */
  @state()
  private products: ProductInterface[] = [];

  @state()
  dialogHidden = true;

  @state()
  private csvParsed = false;

  @state()
  private csvRows: CsvProductInterface[] = [];

  @state()
  private deleteOldProducts = true;

  @state()
  private pendingRequest = false;

  private apiUrl = env.apiUrl;
  private apiPort = env.apiPort;

  connectedCallback(): void {
    super.connectedCallback();

    this.productContent = request<ProductInterface[]>(
      `${this.apiUrl}${this.apiPort}/products/all`,
    );

    this.productContent.then((products) => {
      // Promise has been resolved -> products are loaded
      this.productsLoading = false;
      // Destructoring is needed to trigger re-render
      this.products = [...products];
    });
  }

  render() {
    const fileInputRef = createRef<HTMLInputElement>();

    return html`
      <div id="content-wrapper">
        <app-header ?enableBack="${true}"></app-header>
  
        <h1>Produkte aktualisieren</h1>
  
        <fluent-button @click=${() => (this.dialogHidden = false)}
          >Produkte auswählen (CSV)</fluent-button
        >
  
        ${this.productsLoading
          ? // Loading
            html`<div id="loading-container">
              <fluent-progress-ring></fluent-progress-ring>
              <span>Produkte laden</span>
            </div>`
          : // Loaded
            html`
              <table>
                <tr>
                  <th>Art.Nr</th>
                  <th>Name</th>
                  <th>Preis</th>
                  <th>Einheit</th>
                </tr>
                ${this.products.map(
                  (product) => html` <tr>
                    <td>${product.articleNumber}</td>
                    <td>${product.name}</td>
                    <td class="price">
                      ${product.price.toFixed(2).replace('.', ',')} €
                    </td>
                    <td class="unit">${product.unit}</td>
                  </tr>`,
                )}
              </table>
            `}
  
        <fluent-dialog ?hidden=${this.dialogHidden}>
          <div id="dialog-content">
            <h2>Produkte aktualisieren</h2>
            <!-- File selection -->
            <div id="dialog-file-selection">
              <input
                ${ref(fileInputRef)}
                @change=${() => {
                  const { files } = fileInputRef.value!;
                  const reader = new FileReader();
  
                  reader.onload = async (event: ProgressEvent<FileReader>) => {
                    const csvContent = event.target!.result as string;
  
                    if (csvContent !== null) {
                      /**
                       * Parse content using ';' as a delimiter
                       */
                      const { data } = parse<CsvProductInterface>(csvContent, {
                        delimiter: ';',
                        header: true,
                      });
  
                      this.csvRows = data
                        // Process that data from the second row onwards
                        .filter((row) => Object.keys(row).length > 1)
                        // Convert 'Preis' column values to number
                        .map((product) => {
                          // Format price to use a comma as a delimiter
                          const formattedPrice = Number.parseFloat(
                            product.Preis.toString().replace(',', '.'),
                          );
  
                          return { ...product, Preis: formattedPrice };
                        });
  
                      // CSV has been parsed, show data in a table
                      this.csvParsed = true;
                    }
                  };
  
                  reader.readAsText(files![0], 'cp1252');
                }}
                accept=".csv"
                type="file"
              />
  
              ${this.csvParsed
                ? html``
                : html`<span>Noch keine Produkte ausgewählt</span>`}
            </div>
            <!-- Display CSV products -->
            <div id="dialog-csv-content">
              ${this.csvParsed
                ? html`
                    <table>
                      <tr>
                        <th>Art.Nr</th>
                        <th>Name</th>
                        <th>Preis</th>
                        <th>Einheit</th>
                      </tr>
                      ${this.csvRows.map(
                        (product) => html` <tr>
                          <td>${product['Art.Nr.']}</td>
                          <td>${product.Artikel}</td>
                          <td class="price">
                            ${product.Preis.toFixed(2).replace('.', ',')} €
                          </td>
                          <td class="unit">${product['Eht.']}</td>
                        </tr>`,
                      )}
                    </table>
                  `
                : html``}
            </div>
            <!-- Actions such as save, cancel -->
            <div id="dialog-actions">
              ${this.csvParsed
                ? html`
                    <fluent-radio-group>
                      <fluent-radio
                        @click=${() => (this.deleteOldProducts = true)}
                        checked
                        >Mit neuer CSV ersetzen</fluent-radio
                      >
                    </fluent-radio-group>
  
                    <!-- Upload button -->
                    <fluent-button
                      @click=${async () => {
                        // CSV data in SQLite will be replaced
                        if (this.deleteOldProducts) {
                          this.pendingRequest = true;
                          console.log(this.pendingRequest);
  
                          const deleteProducts = await deleteRequest<
                            ProductInterface[]
                          >(`${this.apiUrl}${this.apiPort}/products/all`);
                          console.log('upload');
                          console.log(deleteProducts);
  
                          const promises = this.csvRows.map(async (product) => {
                            return addProduct(
                              `${this.apiUrl}${this.apiPort}/product/add`,
                              {
                                name: product.Artikel!,
                                price: product.Preis!,
                                articleNumber: product['Art.Nr.']!,
                                unit: product['Eht.']!,
                              },
                            );
                          });
  
                          Promise.all(promises).then(() => {
                            console.log('done');
                            this.pendingRequest = false;
                          });
                        }
                      }}
                      ?disabled=${this.pendingRequest}
                      appearance="accent"
                    >
                      ${this.pendingRequest
                        ? html`
                            <div id="loading-request">
                              <fluent-progress-ring></fluent-progress-ring>
                              <span>Lädt</span>
                            </div>
                          `
                        : html` Produkte aktualisieren `}
                    </fluent-button>
                  `
                : html``}
              <fluent-button
                @click=${() => {
                  this.dialogHidden = true;
                }}
                ?disabled=${this.pendingRequest}
                appearance="accent"
                >Schließen</fluent-button
              >
            </div>
          </div>
        </fluent-dialog>
      </div>
    `;
  }
}
