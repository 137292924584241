import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

@customElement('app-copyright')
export class AppCopyright extends LitElement {
  static styles = css`
    #copyright-notice {
      position: fixed;
      bottom: var(--base-gap);
      left: var(--base-gap);
      border-radius: var(--base-radius);
      padding: calc(var(--base-gap) / 2) var(--base-gap);
      border: 1px solid var(--company-color);
      background-color: #fff;
    }
  `;

  @property({ type: String })
  copyRightNotice = `© ${new Date().getFullYear()}, EAH Jena - Lern und Future Store`;

  render() {
    return html`<span id="copyright-notice">${this.copyRightNotice}</span>`;
  }
}

