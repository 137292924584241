import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('page-changes')
export class PageChanges extends LitElement {
  static get styles() {
    return css`
      #content-wrapper {
        max-width: 600px;
        padding: var(--base-gap);
      }
    `;
  }

  render() {
    return html`
      <div id="content-wrapper">
        <app-header ?enableBack="${true}"></app-header>

        <h2>Änderungsprotokoll (Changelog)</h2>
        <p>
          Im Changelog werden die Änderungen der jeweiligen Version
          protokolliert. Dieses folgt dabei
          <a
            href="https://semver.org/lang/de/"
            target="_blank"
            rel="noopener noreferrer"
            >SemVer</a
          >. Es werden nur sogenannte Major- oder Minor-Updates protokolliert.
        </p>

        <h3>0.4.0</h3>
        <ul>
          <li>Bessere Anordnung der Startseite</li>
          <li>
            Man kann jetzt die Intervalle der Produkt- und Promotionseite in den
            <a href="settings" target="_blank" rel="noopener noreferrer"
              >Einstellungen</a
            >
            ändern
          </li>
          <li>
            Aktiv ist das bereits auf der
            <a
              href="promotions/active"
              target="_blank"
              rel="noopener noreferrer"
              >Promotionseite</a
            >
          </li>
        </ul>

        <h3>0.3.0</h3>
        <ul>
          <li>
            Work in progress: Redesign der
            <a href="/products/all" target="_blank" rel="noopener noreferrer"
              ><code>/products/all</code></a
            >-Seite im CI des Unternehmes
          </li>
          <li>
            fix: Lädt man eine Produkt-CSV hoch, wird diese nicht mehr auf 40
            Stück begrenzt
          </li>
        </ul>

        <h3>0.2.0</h3>
        <ul>
          <li>
            Anleitungen für das Anlegen, Bearbeiten und Löschen von Produkten
            bzw. Promotions wurde zu
            <a href="/help" target="_blank" rel="noopener noreferrer"
              ><code>/help</code></a
            >
            hinzufügt.
          </li>
        </ul>

        <h3>0.1.0</h3>

        <ul>
          <li>Es wurde die Tabelle "settings" in der Datenbank angelegt</li>
          <li>
            Die Felder <code>productInterval</code> und
            <code>promotionInterval</code> können über die API abgefragt werden.
          </li>
          <li>
            Diese Seite (<a href="/"><code>/changes</code></a
            >) ist neu und informiert über Änderungen
          </li>
          <li>
            Es gibt eine Hilfe & FAQ Seite unter
            <a href="/help" target="_blank" rel="noopener noreferrer"
              ><code>/help</code></a
            >
          </li>
          <li>Kleinere Anpassungen in der Formatierung</li>
        </ul>
      </div>
    `;
  }
}

