import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import packageInfo from '../../../package.json';

@customElement('app-version')
export class AppVersion extends LitElement {
  static styles = css`
    #version-tag {
      position: fixed;
      bottom: var(--base-gap);
      right: var(--base-gap);
      border-radius: var(--base-radius);
      padding: calc(var(--base-gap) / 2) var(--base-gap);
      border: 1px solid var(--company-color);
      background-color: #fff;
    }
  `;

  @property({ type: String })
  copyRightNotice = `© ${new Date().getFullYear()}, EAH Jena`;

  render() {
    return html`<span id="version-tag">v${packageInfo.version}</span>`;
  }
}
