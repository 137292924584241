const dateOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  year: 'numeric',
  month: '2-digit',
};
/**
 * Converts a timestamp into a readable date using the german locale
 */
export const toLocaleDate = (timestamp: number): string => {
  return new Date(timestamp).toLocaleDateString('de-DE', dateOptions);
};
/**
 * Converts a timestamp into an ISO string to use it 
 * in a date input field as those must be formated as 'YYYY-mm-dd'
 */
export const toIsoString = (timestamp: number): string => {
  return new Date(timestamp).toISOString().substring(0, 10);
};
