import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('app-product')
export class AppProduct extends LitElement {
  @property({ type: Number })
  price = Math.floor(Math.random() * (20 - 2 + 1) + 2);

  @property({ type: String })
  name = 'product';

  @property({ type: String })
  unit = 'kg';

  @property({ type: Number })
  articleNumber = Math.floor(Math.random() * (420 - 200 + 1) + 200);

  static styles = css`
    fluent-card.product-card {
      display: inline-grid;
      grid-template-columns: minmax(0, 4fr) minmax(0, 1fr);
      grid-template-rows: 100%;
      grid-template-areas: 'product-name price article-number';
      gap: calc(var(--base-gap) * 2.5);
      align-items: center;

      padding: calc(var(--base-gap) * 2);
      max-height: 100%;

      background-color: #222;
      color: white;

      box-sizing: border-box;

      font-size: 4rem;
    }

    .product-card h2 {
      margin: 0;
      font-weight: normal;
      grid-area: product-name;
      word-wrap: break-word;
      font-size: 3.9vw;
    }

    .flex-container {
      display: flex;
      justify-content: space-between;
    }

    #article-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      grid-area: price;
      font-weight: bold;
      font-size: 3.8vw;
    }

    #article-price span:first-child {
      padding-bottom: 4px;
      border-bottom: 3px solid white;
    }

    #article-price span:nth-child(1) {
      word-break: keep-all;
      white-space: nowrap;
    }

    // Not used:
    #article-number {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      grid-area: article-number;

      aspect-ratio: 1;

      border-radius: var(--base-radius);
      background-color: black;
      font-size: 3.8rem;
    }
  `;

  render() {
    return html`
      <fluent-card class="product-card">
        <h2>${this.name}</h2>
        <span id="article-price">
          <span> ${this.price.toFixed(2).replace('.', ',')} € </span>
          <span>${this.unit}</span>
        </span>
      </fluent-card>
    `;
  }
}

