import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { PromotionInterface, SettingsInterface } from '../interfaces';
import { env, request } from '../helper';
import { styleMap } from 'lit/directives/style-map.js';

@customElement('app-promotion-gallery')
export class AppPromotionGallery extends LitElement {
  static styles = css`
    :root {
      --base-gap: 16px;
    }

    #gallery {
      display: flex;
      align-items: flex-start;
      gap: var(--base-gap);

      height: 100vh;
      box-sizing: border-box;
    }

    .gallery-item {
      width: 100%;
      object-fit: contain;
    }

    #gallery::-webkit-scrollbar {
      display: none;
    }
  `;

  private promotionInterval = 30000;

  @state()
  private currentIndex = 0;

  @state()
  private promotionContent!: Promise<PromotionInterface[]>;

  @state()
  private promotions: PromotionInterface[] = [];

  @state()
  private promotionsLoading = true;

  private lastIndex = 0;

  private apiUrl = env.apiUrl;
  private apiPort = env.apiPort;

  private promotionIntervalRoute = `${this.apiUrl}${this.apiPort}/settings/promotionInterval`;
  private promotionIntervallResponse!: Promise<SettingsInterface>;

  connectedCallback(): void {
    super.connectedCallback();

    this.promotionIntervallResponse = request<SettingsInterface>(
      this.promotionIntervalRoute,
    );

    this.promotionIntervallResponse.then((response) => {
      this.promotionInterval = response.promotionInterval
        ? response.promotionInterval * 1000
        : this.promotionInterval;

      console.log(this.promotionInterval);
    });

    this.promotionContent = request<PromotionInterface[]>(
      `${this.apiUrl}${this.apiPort}/promotions/active`,
    );

    this.promotionContent.then((promotions) => {
      // Promise has been resolved -> promotions are loaded
      this.promotionsLoading = false;
      // Destructoring is needed to trigger re-render
      this.promotions = [...promotions];
      this.lastIndex = this.promotions.length - 1;

      console.log(this.promotions);

      // Start a timer when there are more than one active promotion
      if (this.promotions.length > 1) {
        setInterval(() => {
          this.currentIndex =
            this.currentIndex < this.lastIndex
              ? // Increase until last image was displayed
                (this.currentIndex += 1)
              : // Reset to first image
                (this.currentIndex = 0);
        }, this.promotionInterval);
      }
    });
  }

  public render() {
    const currentPromotion = this.promotions[this.currentIndex];
    const promotionTemplate = html`
      <div
        id="gallery"
        style=${styleMap({
          backgroundColor: currentPromotion?.backgroundColor,
        })}
      >
        <img class="gallery-item" src=${currentPromotion?.promotionDataUrl} />
      </div>
    `;

    return html`${this.promotionsLoading
      ? html`
          <div id="loading-container">
            <fluent-progress-ring></fluent-progress-ring>
            <span>Promotions loading</span>
          </div>
        `
      : promotionTemplate}`;
  }
}
