import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { env, DialogEvent } from '../helper';
import { PromotionInterface } from '../interfaces';

@customElement('page-debug')
export class PageDebug extends LitElement {
  @state()
  private dialogHidden = true;

  private isDev = (import.meta as any).env.DEV;
  private isProd = (import.meta as any).env.PROD;
  private mode = (import.meta as any).env.MODE;
  private baseURL = (import.meta as any).env.BASE_URL;
  private apiUrl = env.apiUrl;
  private apiPort = env.apiPort;

  render() {
    return html`
      <app-header ?enableBack="${true}"></app-header>

      <h2>Debug Page</h2>

      <fluent-button
        @click=${() => {
          this.dialogHidden = false;
          console.log(this.dialogHidden);
        }}
        >Dialog öffnen</fluent-button
      >

      <ul>
        <li>isDev: ${this.isDev}</li>
        <li>isProd: ${this.isProd}</li>
        <li>mode: ${this.mode}</li>
        <li>baseURL: ${this.baseURL}</li>
        <li>apiUrl: ${this.apiUrl}</li>
        <li>apiPort: ${this.apiPort}</li>
        <li>endpoint: ${`${this.apiUrl}${this.apiPort}`}</li>
        <li><a href="promotions/edit">promotions/edit</a></li>
      </ul>

      <fluent-button
        @click=${() => {
          window.dispatchEvent(
            new DialogEvent<PromotionInterface>(
              {
                title: 'Katze',
                backgroundColor: '#0fa',
                startDate: Date.now(),
                endDate: Date.now(),
                promotionDataUrl: '',
              },
              true,
            ),
          );
        }}
      >
        DialogEvent
      </fluent-button>

      <promotion-dialog></promotion-dialog>
    `;
  }
}
