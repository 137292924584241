import { createRef, ref } from 'lit/directives/ref.js';
import { customElement, state } from 'lit/decorators.js';
import { DialogEvent, toIsoString } from '../helper';
import { LitElement, html, css } from 'lit';
import { PromotionInterface } from '../interfaces';
import { styleMap } from 'lit/directives/style-map.js';

@customElement('promotion-dialog')
export class PromotionDialog extends LitElement {
  static styles = css`
    fluent-dialog {
      --dialog-height: unset;
    }

    fluent-dialog::part(control) {
      display: grid;
      gap: var(--base-gap);
    }

    #dialog-content {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-areas: 'form promotion-image';
      gap: var(--base-gap);
      padding: var(--base-gap) var(--base-gap) 0;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: var(--base-gap);
    }

    #date-wrapper {
      display: flex;
      gap: var(--base-gap);
    }

    img {
      border-top-left-radius: var(--base-radius);
      border-top-right-radius: var(--base-radius);
      width: 100%;
    }

    #promotion-background-color-input-wrapper {
      display: flex;
      align-items: center;
      gap: var(--base-gap);
    }

    #promotion-image-wrapper {
      display: grid;
      grid-template-rows: auto 35px;
      border: 1px solid #929292;
      border-radius: var(--base-radius);
    }

    #color-preview {
      border-bottom-left-radius: var(--base-radius);
      border-bottom-right-radius: var(--base-radius);
    }

    #dialog-actions {
      display: flex;
      justify-content: flex-end;
      gap: var(--base-gap);
      padding: var(--base-gap);
    }
  `;

  @state()
  dialogHidden: boolean = true;

  @state()
  private validForm = false;

  @state()
  private promotionId = '';

  @state()
  private promotionTitle = '';

  @state()
  private promotionDataUrl: string = '';

  @state()
  private backgroundColor = '#fff';

  @state()
  private editMode: boolean = false;

  @state()
  public startDate: number = Date.now();

  @state()
  private endDate: number = Date.now();

  private formRef = createRef<HTMLFormElement>();
  private imgSelectRef = createRef<HTMLInputElement>();
  /**
   * Uses FileReader to display an image as a DataURL
   */
  private previewImageFile() {
    const reader = new FileReader();
    if (this.imgSelectRef.value?.files) {
      const file = this.imgSelectRef.value?.files[0];

      reader.addEventListener(
        'load',
        () => {
          if (reader.result) {
            this.promotionDataUrl = reader.result as string;
          }
        },
        false,
      );
      reader.readAsDataURL(file);
    }
  }

  constructor() {
    super();

    window.addEventListener(DialogEvent.eventName, (dialogEvent: Event) => {
      const { dialogData } = dialogEvent as DialogEvent<PromotionInterface>;

      this.dialogHidden = !dialogData.dialogOpen;
      this.editMode = dialogData.edit || this.editMode;

      this.promotionId = dialogData.payload?.id || this.promotionId;
      this.promotionTitle = dialogData.payload?.title || this.promotionTitle;
      this.startDate = dialogData.payload?.startDate || this.startDate;
      this.endDate = dialogData.payload?.endDate || this.endDate;
      this.backgroundColor =
        dialogData.payload?.backgroundColor || this.backgroundColor;
      this.promotionDataUrl =
        dialogData.payload?.promotionDataUrl || this.promotionDataUrl;
    });
  }

  private resetForm() {
    this.promotionTitle = '';
    this.startDate = Date.now();
    this.endDate = Date.now();
    this.backgroundColor = '#fff';
    this.promotionDataUrl = '';
  }

  render() {
    return html`
      <fluent-dialog ?hidden=${this.dialogHidden}>
        <div id="dialog-content">
          <div>
            <h2>Promotion ${this.editMode ? 'bearbeiten' : 'anlegen'}</h2>

            <form
              ${ref(this.formRef)}
              @submit=${(e: Event) => e.preventDefault()}
              @change=${() => {
                if (this.formRef) {
                  this.validForm = (
                    this.formRef as HTMLFormElement
                  ).value.checkValidity();
                }
              }}
            >
              <fluent-text-field
                .value=${this.promotionTitle}
                @input=${(e: InputEvent) => {
                  const { value } = e.target as HTMLInputElement;
                  this.promotionTitle = value;
                }}
                appearance="filled"
                placeholder="Namen eingeben"
                required
                name="promotion-title"
              >
                Name der Promotion<span class="required">*</span>
              </fluent-text-field>

              <div id="date-wrapper">
                <fluent-text-field
                  .value=${toIsoString(this.startDate)}
                  @input=${(e: InputEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    const start = new Date(value).getTime();
                    console.log(start);

                    this.startDate = start;
                  }}
                  appearance="filled"
                  type="date"
                  required
                  name="start-date"
                  >Startdatum<span class="required">*</span></fluent-text-field
                >
                <fluent-text-field
                  .value=${toIsoString(this.endDate)}
                  @input=${(e: InputEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    const end = new Date(value).getTime();
                    this.endDate = end;
                  }}
                  appearance="filled"
                  type="date"
                  required
                  name="end-date"
                >
                  Enddatum<span class="required">*</span>
                </fluent-text-field>
              </div>

              <input
                @change=${() => this.previewImageFile()}
                ${ref(this.imgSelectRef)}
                accept="image/*"
                required
                type="file"
                name="image-selection"
              />

              <div id="promotion-background-color-input-wrapper">
                <input
                  .value=${this.backgroundColor}
                  @input=${(e: Event) => {
                    const { value } = e.target as HTMLInputElement;
                    this.backgroundColor = value;
                  }}
                  id="promotion-background-color-input"
                  type="color"
                  name="color-selection"
                />
                <label for="promotion-background-color-input"
                  >Hintergrundfarbe wählen</label
                >
              </div>
            </form>
          </div>
          <!-- Promotion image -->
          <div
            id="promotion-image-wrapper"
            style=${styleMap({
              borderColor: this.backgroundColor,
            })}
          >
            <img alt="Image of a promotion" src=${this.promotionDataUrl} />
            <div
              id="color-preview"
              style=${styleMap({
                backgroundColor: this.backgroundColor,
              })}
            ></div>
          </div>
        </div>
        <div id="dialog-actions">
          <!-- Send an event with promotion data -->
          <fluent-button
            ?disabled=${!this.validForm}
            @click=${() => {
              this.dialogHidden = true;

              const newPromotion: PromotionInterface = {
                id: this.promotionId,
                title: this.promotionTitle,
                backgroundColor: this.backgroundColor,
                promotionDataUrl: this.promotionDataUrl,
                startDate: this.startDate,
                endDate: this.endDate,
              };

              window.dispatchEvent(
                new DialogEvent<PromotionInterface>(
                  newPromotion,
                  false, // open
                  this.editMode, // editMode?
                  true, // data sent?
                ),
              );

              this.editMode = false;
              this.resetForm();
            }}
            appearance="accent"
          >
            ${this.editMode ? 'Speichern' : 'Hinzufügen'}
          </fluent-button>
          <!-- Close dialog -->
          <fluent-button
            @click=${() => {
              this.dialogHidden = true;

              this.resetForm();
            }}
            >Abbrechen</fluent-button
          >
        </div>
      </fluent-dialog>
    `;
  }
}
