interface DialogInterface<T> {
  dialogOpen: boolean;
  payload?: T;
  edit?: boolean;
  dataSent?: boolean;
}

export class DialogEvent<T> extends Event {
  static readonly eventName = 'dialog-event' as const;
  dialogData: DialogInterface<T>;

  constructor(dialogData: T, open = false, edit = false, dataSent = false) {
    super(DialogEvent.eventName, { bubbles: true });

    this.dialogData = {
      dialogOpen: open,
      payload: dialogData,
      edit,
      dataSent,
    };
  }
}
